import { Controller } from "@hotwired/stimulus"

const DAILY_BREAKDOWN_HARD_RESET_FIELDS = [
  "leave_request[start_time]",
  "leave_request[finish_time]",
  "leave_request[start_date]",
  "leave_request[finish_date]",
  "leave_request[leave_type]",
]
export default class extends Controller {
  static targets = [
    "status",
    "dailyBreakdown",
    "dailyBreakdownContainer",
    "dailyBreakdownField",
    "spinnerIconTemplate",
    "delta",
  ]

  static values = {
    formDataKey: String,
    updatePath: String,
    requestId: String,
  }

  static classes = ["loading"]

  initialize() {
    this.showSpinner()
    this.reload()
    this.element.addEventListener("DailyBreakdownReload", this.reload.bind(this))
  }

  clearDelta() {
    this.deltaTarget.textContent = ""
  }

  update(ev) {
    if (this.isOpen()) {
      this.reload(ev)
    } else {
      this.showSpinner()
    }
  }

  handleOpenToggle() {
    if (this.isOpen()) {
      if (this.needsUpdate) {
        this.update()
      }
      this.needsUpdate = false
    }
  }

  showSpinner() {
    this.needsUpdate = true
    if (this.hasSpinnerIconTemplateTarget) {
      const spinner = this.spinnerIconTemplateTarget.content.cloneNode(true)
      this.dailyBreakdownTarget.replaceChildren(spinner)
    }
  }

  reload(ev = {}) {
    const url = new URL(this.updatePathValue, location)

    const weekStart = ev.params ? ev.params.weekStart : null

    const data = new FormData(this.element)

    url.searchParams.set("start_time", data.get(`${this.formDataKeyValue}[start_time]`))
    const startDate = data.get(`${this.formDataKeyValue}[start_date]`)
    url.searchParams.set("start_date", startDate)
    url.searchParams.set("all_day", data.get(`${this.formDataKeyValue}[all_day]`))
    url.searchParams.set("finish_time", data.get(`${this.formDataKeyValue}[finish_time]`))

    // Only put hours into the params if they were manually updated. Hours in breakdown params means
    // the breakdown will try to split the given hours over all the days.
    if (ev.target?.name === `${this.formDataKeyValue}[hours]`) {
      url.searchParams.set("hours", data.get(`${this.formDataKeyValue}[hours]`))
    }

    // In the new modal, if the all day radio button is checked then no finish date input is visible
    // In that case finish date fallsback to the start date
    url.searchParams.set("finish_date", data.get(`${this.formDataKeyValue}[finish_date]`) || startDate)

    url.searchParams.set("leave_type", data.get(`${this.formDataKeyValue}[leave_type]`))

    // Mobile view and desktop view structure their forms a bit differently, necessitating this.
    const user_id = data.get("id") || data.get(`${this.formDataKeyValue}[user_id]`)

    url.searchParams.set("user_id", user_id)
    url.searchParams.set("request_id", this.requestIdValue)
    url.searchParams.set("status", this.getStatus())
    url.searchParams.set("week_start", weekStart)

    // If we're recalculating due to the start date/finish date/times/leave type changing,
    // trash any edits to the breakdown and regenerate it from scratch
    if (!DAILY_BREAKDOWN_HARD_RESET_FIELDS.includes(ev.target?.name)) {
      this.dailyBreakdownFieldTargets.forEach((field) => url.searchParams.append("daily_breakdown[]", field.value))
    }

    this.dailyBreakdownTarget.src = url.toString()
    this.needsUpdate = false
    this.startLoading()
  }

  startLoading() {
    if (!this.isOpen()) {
      return
    }
    this.dailyBreakdownTarget.classList.add(...this.loadingClasses)
    this.dailyBreakdownTarget.setAttribute("inert", "true")
  }

  finishLoading() {
    this.dailyBreakdownTarget.classList.remove(...this.loadingClasses)
    this.dailyBreakdownTarget.removeAttribute("inert")
    const breakdownHoursChangeEvent = new CustomEvent("breakdownHoursChange")
    window.dispatchEvent(breakdownHoursChangeEvent)
  }

  getStatus() {
    // Status field is type=checkbox when the user can auto-approve, otherwise type=hidden
    if (this.statusTarget.type === "checkbox") {
      return this.statusTarget.checked ? "approved" : "pending"
    } else {
      return this.statusTarget.value
    }
  }

  isOpen() {
    if (this.dailyBreakdownContainerTarget.tagName === "DETAILS") {
      return this.dailyBreakdownContainerTarget.open || this.dailyBreakdownContainerTarget.getAttribute("aria-expanded")
    }
    return true
  }
}
