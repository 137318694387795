/* global VanillaCalendar */

import moment from "moment"

import { Controller } from "@hotwired/stimulus"
import colors from "../../../../../../tailwind.config"

export default class extends Controller {
  static targets = ["date", "input"]
  static values = {
    prevStart: String,
    nextStart: String,
    start: String,
    end: String,
    id: String,
    frequency: String,
    firstDay: Number,
  }

  constructPayPeriods = () => {
    if (this.frequencyValue !== "fortnightly" && this.frequencyValue !== "weekly") {
      return []
    }

    const payPeriods = []

    const frequency = this.frequencyValue
    const amountToIncrement = frequency === "fortnightly" ? 14 : 7

    // Loop over 50 future pay periods
    let startDate = new Date(this.startValue)
    for (let i = 0; i < 50; i++) {
      const endDate = new Date(startDate)
      endDate.setDate(endDate.getDate() + amountToIncrement)
      payPeriods.push({ start: startDate, end: endDate })
      startDate = endDate
    }

    // Loop over 50 past pay periods
    let pastStartDate = new Date(this.startValue)
    for (let i = 0; i < 50; i++) {
      const endDate = new Date(pastStartDate)
      endDate.setDate(endDate.getDate() - amountToIncrement)
      payPeriods.push({ start: endDate, end: pastStartDate })
      pastStartDate = endDate
    }
    return payPeriods.sort((a, b) => a.start - b.start)
  }

  getCurrentPayPeriodIndex() {
    return this.payPeriods.findIndex((payPeriod) => payPeriod.start.getTime() === new Date(this.startValue).getTime())
  }

  disableFastFiltersContent() {
    document
      .querySelector("[data-fast-filters-target='content']")
      .classList.add("animate-pulse", "cursor-wait", "opacity-60")
  }

  prev() {
    this.dateTarget.value = this.prevStartValue
    this.submitClosestForm()
  }

  next() {
    this.dateTarget.value = this.nextStartValue
    this.submitClosestForm()
  }

  submitClosestForm = () => {
    this.disableFastFiltersContent()
    this.element.closest("form").requestSubmit()
  }

  formatDate = (date) => {
    const userLang = navigator.language || "en"
    return new Intl.DateTimeFormat(userLang, {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
      .format(new Date(date))
      .replace(",", "")
  }

  connect() {
    const frequency = this.frequencyValue
    const inputTarget = this.inputTarget
    const dateTarget = this.dateTarget
    const formatDate = this.formatDate
    const submitClosestForm = this.submitClosestForm
    const startValue = this.startValue

    const payPeriods = this.constructPayPeriods()
    this.payPeriods = payPeriods

    const options = {
      input: true,
      type: "multiple",
      months: 2,
      jumpMonths: 1,
      positionToInput: ["bottom", "right"],
      settings: {
        selection: {
          day: "single",
        },
        selected: {
          dates: [`${this.startValue}_${this.endValue}`],
        },
        visibility: {
          theme:
            window.localStorage.getItem("DARK_MODE") === "1" && window.location.pathname.includes("mobile_app")
              ? "dark"
              : "light",
          daysOutside: false,
        },
        lang: navigator.language || "en",
      },
      actions: {
        getDays(day, date, HTMLElement, HTMLButtonElement, self) {
          if (frequency === "fortnightly" || frequency === "weekly") {
            const payPeriod = payPeriods.find((payPeriod) => {
              const thisDate = new Date(date)
              return thisDate >= payPeriod.start && thisDate < payPeriod.end
            })
            if (payPeriod) {
              const payPeriodId = payPeriod.start.toISOString().split("T")[0]
              HTMLButtonElement.dataset.payPeriodId = payPeriodId
              HTMLButtonElement.dataset.startDate = payPeriod.start.toISOString().split("T")[0]
              HTMLButtonElement.dataset.endDate = payPeriod.end.toISOString().split("T")[0]
              HTMLButtonElement.addEventListener("mouseover", (e) => {
                const thisGrouping = e.target.dataset.payPeriodId
                e.target.style.backgroundColor = colors.theme.colors.gray[200]
                self.HTMLElement.querySelectorAll(`[data-pay-period-id="${thisGrouping}"]`).forEach((el) => {
                  el.style.backgroundColor = colors.theme.colors.gray[200]
                })
              })
              HTMLButtonElement.addEventListener("mouseout", (e) => {
                const thisGrouping = e.target.dataset.payPeriodId
                e.target.style.backgroundColor = "transparent"
                self.HTMLElement.querySelectorAll(`[data-pay-period-id="${thisGrouping}"]`).forEach((el) => {
                  el.style.backgroundColor = "transparent"
                })
              })
            }
          }
        },
        clickDay(e, self) {
          if (frequency === "fortnightly" || frequency === "weekly") {
            dateTarget.value = e.target.dataset.startDate
            // End date is always 1 date before
            const endDate = new Date(e.target.dataset.endDate)
            endDate.setDate(endDate.getDate() - 1)
            inputTarget.value = `${formatDate(e.target.dataset.startDate)} - ${formatDate(endDate)}`
            self.settings.selected.dates = [`${e.target.dataset.startDate}_${endDate.toISOString().split("T")[0]}`]
            self.update({ dates: true })
          } else {
            // Handle monthly pay period.

            const selectedDate = new Date(e.target.dataset.calendarDay)
            const payPeriodStartingDayOfMonth = new Date(startValue).getDate()
            const selectedDateDayOfMonth = selectedDate.getDate()

            if (selectedDateDayOfMonth > payPeriodStartingDayOfMonth) {
              // Select the starting date within the current month
              const currentMonth = selectedDate.getMonth()
              const currentYear = selectedDate.getFullYear()

              const newStartDate = moment()
                .year(currentYear)
                .month(currentMonth)
                .date(payPeriodStartingDayOfMonth)
                .startOf("day")
              dateTarget.value = newStartDate.format("YYYY-MM-DD")
            } else {
              // Select the starting date within the previous month
              const currentMonth = selectedDate.getMonth()
              const currentYear = selectedDate.getFullYear()

              const newStartDate = moment()
                .year(currentYear)
                .month(currentMonth - 1)
                .date(payPeriodStartingDayOfMonth)
                .startOf("day")
              dateTarget.value = newStartDate.format("YYYY-MM-DD")
            }
          }
          submitClosestForm()
        },
      },
    }
    const calendar = new VanillaCalendar(`#${this.idValue}`, options)
    calendar.init()
  }
}
