import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["occupationalCategory", "managerCategory"]

  connect() {
    this.update()
  }

  update() {
    if (/^0{0,3}1/.test(this.occupationalCategoryTarget.value)) {
      this.managerCategoryTarget.classList.remove("hidden")
    } else {
      this.managerCategoryTarget.classList.add("hidden")
    }
  }

  fillOccupationalZeroes() {
    const originalValue = this.occupationalCategoryTarget.value.trim()
    if (!originalValue) return
    this.occupationalCategoryTarget.value = originalValue.padStart(4, "0")
  }
}
