/* global VanillaCalendar */

import { Controller } from "@hotwired/stimulus"
import { escapeHTML } from "helpers/dom"
import I18n from "helpers/i18n"

export default class extends Controller {
  static values = {
    id: String,
    data: Object,
  }

  connect() {
    const calendarSettings = {
      settings: {
        visibility: {
          theme: "light",
          weekend: false,
        },
        selection: {
          day: false,
        },
        selected: {
          dates: [`${this.dataValue.start}:${this.dataValue.finish}`],
          month: this.dataValue.month,
          year: this.dataValue.year,
        },
      },
      popups: this.formatEvents(),
      actions: {
        getDays(day, date, HTMLElement, HTMLButtonElement, self) {
          if (Object.keys(eventsForDots).includes(date)) {
            HTMLButtonElement.style.flexDirection = "column"

            // eslint-disable-next-line no-unsanitized/property
            HTMLButtonElement.innerHTML = `
              <span>${day}</span>
              <div class="flex space-x-1 items-center justify-center">${eventsForDots[date]
                .map((event) => `<span class="calendar-indicator ${escapeHTML(event)}"></span>`)
                .join("")}</div>
            `
          }
        },
      },
    }

    const eventsForDots = this.dotsData(this.eventsByDate())
    const eventsCalendar = new VanillaCalendar(`#calendar-events-${this.idValue}`, calendarSettings)

    eventsCalendar.init()
  }

  eventsByDate() {
    const holidays = this.dataValue.holidays.map((obj) => ({ ...obj, type: "holiday" }))
    const schedules = this.dataValue.schedules.map((obj) => ({ ...obj, date: obj.date, type: "schedule" }))
    // Creates an array for events with all dates between start and finish
    const getDatesInRange = (startDate, endDate) => {
      const dateArray = []
      const currentDate = new Date(startDate)
      while (currentDate <= new Date(endDate)) {
        dateArray.push(new Date(currentDate).toDateString())
        currentDate.setDate(currentDate.getDate() + 1)
      }
      return dateArray
    }
    const events = this.dataValue.events.flatMap((obj) => {
      const datesInRange = getDatesInRange(obj.start, obj.finish)
      return datesInRange.map((date) => ({ ...obj, date, type: "event" }))
    })
    // Combine all events into a single array
    const allEvents = [...holidays, ...events, ...schedules]
    // Group events by date
    const eventsByDate = {}
    allEvents.forEach((event) => {
      const eventDate = event.date
      if (eventsByDate[eventDate]) {
        eventsByDate[eventDate].push(event)
      } else {
        eventsByDate[eventDate] = [event]
      }
    })
    return eventsByDate
  }

  dotsData(eventsByDate) {
    const eventsArrayByDay = {}
    Object.keys(eventsByDate).forEach((date) => {
      const typesArray = [...new Set(eventsByDate[date].map((thisDate) => thisDate.type))]
      eventsArrayByDay[date] = typesArray
    })
    return eventsArrayByDay
  }

  formatEvents() {
    const eventsByDate = this.eventsByDate()
    const formattedEvents = {}
    Object.keys(eventsByDate).forEach((date) => {
      const events = eventsByDate[date]
      formattedEvents[date] = {
        html: events
          .map(
            (event) =>
              `<div class="text-xs whitespace-nowrap">${
                event.type === "schedule"
                  ? `${I18n.t("js.leave.shift")}: ${escapeHTML(event.time)}`
                  : escapeHTML(event.name)
              }</div>`
          )
          .join(""),
      }
    })
    return formattedEvents
  }
}
