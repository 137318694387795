import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["nestIntegrationFields", "nameField", "qualifyingEarningsHelpText"]

  showHideQualifyingHelptext(e) {
    const qualifyingEarningsHelpText = this.qualifyingEarningsHelpTextTarget

    if (e.target.value === "qualifying_earnings") {
      qualifyingEarningsHelpText.style.display = "block"
    } else {
      qualifyingEarningsHelpText.style.display = "none"
    }
  }

  showHideNestFields(e) {
    if (e.target.value === "Nest") {
      this.nestIntegrationFieldsTarget.classList.remove("hidden")
      this.nameFieldTarget.classList.add("hidden")
    } else {
      this.clearNestFields()
      this.nestIntegrationFieldsTarget.classList.add("hidden")
      this.nameFieldTarget.classList.remove("hidden")
    }
  }

  clearNestFields() {
    this.nestIntegrationFieldsTarget
      .querySelectorAll("input[type='text'], input[type='password']")
      .forEach((target) => {
        target.value = ""
      })
  }
}
