import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "menuItem",
    "content",
    "commentsCount",
    "stepMenu",
    "stepContent",
    "handleSchedulesStrategyHiddenField",
  ]
  static values = {
    id: String,
    data: Object,
  }

  updateHandleSchedulesStrategyHiddenField(event) {
    this.handleSchedulesStrategyHiddenFieldTarget.value = event.target.value
  }

  changeTab(e) {
    const target = e ? e.currentTarget : { dataset: { id: "request" } }
    const isMenuClick = !!e
    this.menuItemTargets.forEach((menuItem) => {
      if (menuItem.dataset.id !== "decline") {
        const isCurrentMenuItem = menuItem.dataset.id === target.dataset.id
        if (isCurrentMenuItem) {
          menuItem.classList.add("active", "border-b-blue-700", "opacity-100")
          menuItem.classList.remove("border-b-transparent", "opacity-60")
        } else {
          menuItem.classList.remove("active", "border-b-blue-700", "opacity-100")
          menuItem.classList.add("border-b-transparent", "opacity-60")
        }
      }
    })
    this.contentTargets.forEach((content) => {
      content.classList.add("hidden")
    })
    const contentTarget = this.contentTargets.find((content) => content.id === target.dataset.id)
    contentTarget.classList.remove("hidden")
    if (target.dataset.id === "request" && isMenuClick) {
      // resets the leave step to the first step
      this.changeLeaveStep()
    }
  }

  changeLeaveStep(e) {
    const target = e ? e.currentTarget : { dataset: { id: "date" } }
    this.stepMenuTargets.forEach((menuItem) => {
      const isCurrentMenuItem = menuItem.dataset.id === target.dataset.id
      if (isCurrentMenuItem) {
        menuItem.classList.add("bg-white", "shadow-md")
      } else {
        menuItem.classList.remove("bg-white", "shadow-md")
      }
    })
    this.stepContentTargets.forEach((content) => {
      content.classList.add("hidden")
    })
    const contentTarget = this.stepContentTargets.find((content) => content.id === target.dataset.id)
    if (contentTarget) {
      contentTarget.classList.remove("hidden")
      contentTarget.scrollTop = 0
    }
    // Moves the tab back to the request when a leave step is selected
    if (this.element.querySelector("#request").classList.contains("hidden")) {
      this.changeTab()
    }
  }

  incrementCommentCount() {
    const target = this.commentsCountTarget.querySelector("span")
    const currentCount = parseInt(target.innerText)
    const newCount = currentCount + 1
    if (newCount >= 1) {
      this.commentsCountTarget.classList.remove("hidden")
    }
    target.innerText = newCount
  }

  decrementCommentCount() {
    const target = this.commentsCountTarget.querySelector("span")
    const currentCount = parseInt(target.innerText)
    const newCount = currentCount - 1
    if (newCount <= 0) {
      this.commentsCountTarget.classList.add("hidden")
    }
    target.innerText = newCount
  }
}
