/**
 * @flow
 */

export default function debounce<T>(func: (...args: Array<T>) => mixed, delay: number): (...args: Array<T>) => mixed {
  let timeoutId
  return function (...args: Array<T>): mixed {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      func.apply(this, args)
    }, delay)
  }
}
