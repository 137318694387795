import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["hsaContent", "otherContent", "healthBenefit"]
  static values = { condition: String }

  toggle(event) {
    const currentValue = event.target.value

    if (this.conditionValue === currentValue) {
      this.show(this.hsaContentTarget)
      this.hide(this.otherContentTarget)
      this.healthBenefitTarget.checked = true
    } else {
      this.hide(this.hsaContentTarget)
      this.show(this.otherContentTarget)
    }
  }

  hide(target) {
    target.style.display = "none"
  }

  show(target) {
    target.style.display = ""
  }
}
