import { Controller } from "@hotwired/stimulus"
import { escapeHTML } from "helpers/dom"

export default class extends Controller {
  static targets = ["currentItem", "formValue", "item"]

  previouslyHighlightedItem = null

  connect() {
    if (this.hasFormValueTarget) {
      const initialValue = this.formValueTarget.value
      const initialItem = this.element.querySelector(`[data-value="${CSS.escape(initialValue)}"]`)
      if (!initialItem) return

      this.highlightItem(initialItem)
    }
  }

  selectItem(e) {
    const name = e.currentTarget.dataset.name
    const value = e.currentTarget.dataset.value
    const subtitle = e.currentTarget.dataset.subtitle
    const colour = e.currentTarget.dataset.colour

    this.highlightItem(e.currentTarget)

    // safe innerHTML since all text escaped
    // eslint-disable-next-line no-unsanitized/property
    this.currentItemTarget.innerHTML = `<div class="flex items-center">${
      colour ? `<div class="w-1 h-3 rounded-sm ml-1 mr-2" style="background-color: ${escapeHTML(colour)}"></div>` : ""
    }<h6 class="font-normal truncate">${escapeHTML(name)}</h6></div>${
      subtitle ? `<p class="text-gray-600 text-sm truncate">${escapeHTML(subtitle)}</p>` : ""
    }`
    this.formValueTarget.value = value

    this.application.getControllerForElementAndIdentifier(this.element, "design-components--dropdown-menu").close()
  }

  selectFirstItem(ev) {
    const firstItem = this.itemTargets.find((target) => !target.hasAttribute("data-filtered-out"))

    if (!firstItem) {
      ev.preventDefault()
      return
    }

    this.selectItem({ currentTarget: firstItem })
  }

  updateFormFieldMobile(e) {
    this.formValueTarget.value = e.currentTarget.value
  }

  highlightItem(element) {
    if (this.previouslyHighlightedItem) {
      this.previouslyHighlightedItem.autofocus = false
      this.previouslyHighlightedItem.classList.remove("bg-gray-75")
    }

    element.autofocus = true
    element.classList.add("bg-gray-75")

    this.previouslyHighlightedItem = element
  }
}
