import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["parentProgressElement", "childProgressElement"]
  static values = {
    refreshInterval: Number,
  }

  #timeout

  connect() {
    this.#timeout = setTimeout(() => this.monitorProgress(), this.refreshIntervalValue)
  }

  disconnect() {
    clearTimeout(this.#timeout)
    this.#timeout = null
  }

  monitorProgress() {
    const total = this.childProgressElementTargets.length * 100

    const running_totals = this.childProgressElementTargets.map((progressElement) => {
      const subProgressBar = progressElement.querySelector("[data-controller=design-components--progress]")

      // If we can no longer find the sub progress bar, we assume it has completed.
      const current_progress = subProgressBar ? subProgressBar.value : 100
      return current_progress
    })

    const running_total = running_totals.reduce((total, running) => total + running, 0)

    const progress = (running_total / total) * 100

    this.parentProgressElementTarget.setAttribute("value", progress)

    if (progress >= 100) {
      setTimeout(() => this.dispatch("complete", { detail: {} }), 300)
    } else {
      this.#timeout = setTimeout(() => this.monitorProgress(), this.refreshIntervalValue)
    }
  }
}
