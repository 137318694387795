import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["barChartDay"]

  reviewLeave(event) {
    event.preventDefault()
    const src = event.params.src
    const leavePanel = document.querySelector(".leave-panel")
    leavePanel.id = `leave_request_${event.params.id}`
    leavePanel.src = src
    document.querySelectorAll(".leave-card").forEach((card) => {
      card.classList.remove("bg-gray-75")
    })
    event.target.closest(".leave-card").classList.add("bg-gray-75")
  }

  highlightPartDay(event) {
    event.preventDefault()
    const src = event.params.src
    const leavePanel = document.querySelector(".leave-panel")
    leavePanel.id = `leave_request_${event.params.id}`
    leavePanel.src = src
    document.querySelectorAll(".leave-card").forEach((card) => {
      card.classList.remove("bg-gray-75")
    })
    document.querySelector(`.leave-card[data-review-leave-id-param="${event.params.id}"]`).classList.add("bg-gray-75")
  }

  applyLoadingState() {
    const leaveCards = document.querySelector(".leave-cards-inner")
    const leavePanel = document.querySelector(".leave-panel")
    leaveCards.classList.add("busy")
    leavePanel.classList.add("busy")
  }

  highlightDate(event) {
    event.preventDefault()
    this.applyLoadingState()
    const selectedDate = event.target.closest("button")
    const closestForm = selectedDate.closest("form")
    const isExpanded = selectedDate.hasAttribute("expanded")
    if (isExpanded) {
      selectedDate.removeAttribute("expanded")
      selectedDate.classList.remove("highlighted-day")
      closestForm.querySelector(".original-dates").removeAttribute("disabled")
      closestForm.querySelector(".selected-dates").setAttribute("disabled", "disabled")
      localStorage.setItem("day-view-toggle", "list-view")
    } else {
      this.barChartDayTargets.forEach((day) => {
        if (day !== selectedDate) {
          day.classList.remove("highlighted-day")
          day.removeAttribute("expanded")
        }
      })
      selectedDate.classList.add("highlighted-day")
      selectedDate.setAttribute("expanded", "true")
      closestForm.querySelector(".original-dates").setAttribute("disabled", "disabled")
      closestForm.querySelector(".selected-dates").removeAttribute("disabled")
    }
    closestForm.requestSubmit()
  }

  removeSpotlight() {
    const selectedDate = [...document.querySelectorAll(".bar-chart-day")].find((day) => day.hasAttribute("expanded"))
    selectedDate.click()
  }

  findAndClickDay(startDate, increment) {
    let day = startDate
    let found = false
    let iterations = 0

    while (!found && iterations < 30) {
      const dayBtn = document.querySelector(`button[data-date="${day}"]`)
      if (dayBtn) {
        dayBtn.click()
        found = true
      } else {
        iterations += 1
        day = new Date(new Date(day).setDate(new Date(day).getDate() + increment)).toISOString().split("T")[0]
      }
    }
  }

  prevDay(e) {
    this.findAndClickDay(e.params.date, -1)
  }

  nextDay(e) {
    this.findAndClickDay(e.params.date, 1)
  }
}
