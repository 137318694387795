import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["periodTypeField", "anchorDateTypeField", "notApplicableField", "customAnchorDateField"]

  toggleAnchorDateType(event) {
    if (event.target.value === "pay_cycle") {
      this.hideAnchorDateType()
    } else {
      this.showAnchorDateType()
    }
  }

  hideAnchorDateType(show) {
    this.anchorDateTypeFieldTarget.style.display = "none"
    this.customAnchorDateFieldTarget.style.display = "none"
    this.notApplicableFieldTarget.style.display = ""
  }

  showAnchorDateType() {
    this.anchorDateTypeFieldTarget.style.display = ""
    this.notApplicableFieldTarget.style.display = "none"

    if (this.anchorDateTypeFieldTarget.querySelector("input:checked")?.value === "custom") {
      this.customAnchorDateFieldTarget.style.display = ""
    }
  }
}
