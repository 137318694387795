import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["nestMembershipGroupName", "nestMembershipPaymentSource"]

  connect() {}

  showHideNestSettings(e) {
    const selected_provider = e.target.options[e.target.selectedIndex]
    if (selected_provider.textContent === "Nest") {
      this.nestMembershipGroupNameTarget.classList.remove("hidden")
      this.nestMembershipPaymentSourceTarget.classList.remove("hidden")
    } else {
      this.nestMembershipGroupNameTarget.classList.add("hidden")
      this.nestMembershipPaymentSourceTarget.classList.add("hidden")
    }
  }
}
