import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { collapseOthersGroupKey: String, expanded: Boolean }
  static targets = ["caret", "dropdown", "expandBtn", "content"]

  connect() {
    if (this.expandedValue) {
      this.expand()
    }
  }

  toggle() {
    if (this.dropdownTarget.classList.contains("grid-rows-[0fr]")) {
      this.expand()

      if (this.hasCollapseOthersGroupKeyValue) {
        this.collapseOthers()
      }
    } else {
      this.collapse()
    }
  }

  expand() {
    this.contentTarget.classList.remove("hidden")
    if (this.hasCaretTarget) {
      this.caretTarget.classList.add("-rotate-180")
    }
    this.dropdownTarget.classList.replace("grid-rows-[0fr]", "grid-rows-[1fr]")
    this.expandBtnTarget.setAttribute("aria-expanded", "true")
  }

  collapse() {
    if (this.hasCaretTarget) {
      this.caretTarget.classList.remove("-rotate-180")
    }
    this.dropdownTarget.classList.replace("grid-rows-[1fr]", "grid-rows-[0fr]")
    this.expandBtnTarget.setAttribute("aria-expanded", "false")
    this.contentTarget.classList.add("hidden")
  }

  collapseOthers() {
    this.application.controllers
      .filter((controller) => controller.identifier === "accordion")
      .forEach((controller) => {
        if (
          controller.collapseOthersGroupKeyValue === this.collapseOthersGroupKeyValue &&
          controller.element !== this.element
        ) {
          controller.collapse()
        }
      })
  }
}
