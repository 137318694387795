import { Controller } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"
import debounce from "../../helpers/debounce"

export default class extends Controller {
  static targets = ["content", "filtersForm", "sortBy"]

  static values = {
    filterKeys: Array,
  }

  disableTableWhileUpdating() {
    this.contentTargets.forEach((contentTarget) => {
      contentTarget.classList.add("animate-pulse", "cursor-wait", "opacity-60")
    })
  }

  applyFilters(event) {
    if (event.target.dataset.skipFastFiltersValue && event.target.dataset.skipFastFiltersValue === event.target.value) {
      return
    }
    const debounceAmount = event.target.type === "search" ? 300 : 0
    const debouncedFilter = debounce(() => {
      this.disableTableWhileUpdating()
      const mainUrl = new URL(window.origin + window.location.pathname)
      const formData = new FormData(this.filtersFormTarget)
      formData.forEach((value, key) => {
        if (key === "authenticity_token") return
        if (this.hasFilterKeysValue && !this.filterKeysValue.includes(key)) return
        mainUrl.searchParams.append(key, value)
      })
      Turbo.visit(mainUrl.toString())
    }, debounceAmount)
    debouncedFilter(event)
  }

  updateSortByAndApplyFilters(event) {
    this.sortByTarget.value = event.target.value
    this.applyFilters(event)
  }
}
