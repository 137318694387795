import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    startDate: String,
    finishDate: String,
  }

  connect() {
    this.highlightLeaveDemandDates()
    if (this.demandChart) {
      this.demandChart.addEventListener("turbo:frame-load", this.highlightLeaveDemandDates.bind(this))
    }
  }

  disconnect() {
    if (this.demandChart) {
      this.demandChart.removeEventListener("turbo:frame-load", this.highlightLeaveDemandDates.bind(this))
    }
  }

  get demandChart() {
    return document.querySelector("#leave_demand_chart")
  }

  highlightLeaveDemandDates(event) {
    // Remove existing highlights
    const highlightedElements = document.querySelectorAll(".bar-chart-day.selected-day-view-day")
    highlightedElements.forEach((element) => {
      element.classList.remove("selected-day-view-day")
    })

    // Get range and all dates in between
    const startDate = this.startDateValue
    const finishDate = this.finishDateValue
    const dates = this.getAllDatesBetween(startDate, finishDate)

    // Highlight each date if element exists
    const dateElements = document.querySelectorAll("[data-date]")
    dateElements.forEach((element) => {
      const elementDate = element.dataset.date
      if (dates.includes(elementDate)) {
        element.classList.add("selected-day-view-day")
      }
    })
  }

  getAllDatesBetween(startDate, finishDate) {
    const start = new Date(startDate)
    const finish = new Date(finishDate)
    const dates = []

    for (let date = new Date(start); date <= finish; date = new Date(date.setDate(date.getDate() + 1))) {
      dates.push(this.formatDate(new Date(date)))
    }

    return dates
  }

  formatDate(date) {
    return date.toISOString().split("T")[0] // "YYYY-MM-DD"
  }
}
